import React, {useContext, useState, useEffect} from 'react'
import Axios from 'axios'

import StateContext from '../../StateContext'
import { getCookie } from '../../helpers/auth'
import UserLogo from './user.png'

const TopBar = () => {
	const appState = useContext(StateContext)
	const [picUrl, setPicUrl] = useState()

	useEffect(() => {
		getCurrentUser()
	}, [])

	const getCurrentUser = async () => {
		const token = getCookie("token")
		try {
			let response = await Axios.get(`${process.env.REACT_APP_API}/info`, { 
				headers: {
					Authorization: token
				}
			})
			if (response.data.logo && response.data.logo.url && response.data.logo.url.length) {
				setPicUrl(response.data.logo.url)
			}
		} catch(err) {
			console.log(err)
		}
	}

	const toggleHandler = () => {
        document.querySelector(".accordion").classList.toggle('toggled')
    }

	return (
		<nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
			<button onClick = { toggleHandler } id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
				<i className="fa fa-bars"></i>
			</button>
			<ul className="navbar-nav ml-auto">

				

				
				<div className="topbar-divider d-none d-sm-block"></div>

				<li className="nav-item dropdown no-arrow">
					<div className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
					data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						<span className="mr-2 d-none d-lg-inline text-gray-600 small">
						{appState.user.name}
						</span>
						{picUrl && picUrl.length ? (
							<img className = "img-profile rounded-circle" src = { picUrl } />
						) : (
							<img className = "img-profile rounded-circle" src={ UserLogo } />
						)
						}
					</div>
				</li>
			</ul>
		</nav>
	)
}

export default TopBar