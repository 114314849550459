import React, {useContext, useState} from 'react'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { CSSTransition } from "react-transition-group"
import { Link } from 'react-router-dom'

import UserPage from '../../pages/UserPage/UserPage'
import StateContext from '../../StateContext'
import ChequePreview from '../Cheque/ChequePreview'
import Scroll from '../Scroll/Scroll'

const PrintQ = ( {state, dispatch, submitDataHandler, currentUser, currentAccount, online} ) => {
	const appState = useContext(StateContext)
	const [showPrev, setShowPrev] = useState(true)
	const [showTFPrev, setShowTFPrev] = useState(true)

	const onChangePayeeHandler = (e) => {
		const selectedIndex = e.target.options.selectedIndex
        let payeeId = e.target.options[selectedIndex].getAttribute('payeeId')
        dispatch({type: "payeeChange", value: e.target.value, id: payeeId})
	}

	const closePrev = () => {
		setShowPrev(false)
	}

	const openPrev = () => {
		setShowPrev(true)
	}

	const closeTop = () => {
		setShowPrev(false)
		setShowTFPrev(false)
	}

	return (
		<React.Fragment>
		<div className="container">
		<div className="row justify-content-center">
		{state.accounts.length &&
		<React.Fragment>
		{showTFPrev &&
		<div className = "topFixed topFixed--middle">
		<div className = "toggleMnuBar">
		{showPrev && <div onClick = { closePrev } className = "minimizer"><i class="fas fa-window-minimize"></i></div>}
		{!showPrev && <div onClick = { openPrev }><i className="fas fa-plus"></i></div>}
		<div onClick = { closeTop }><i className="fas fa-times"></i></div>
		</div>
		</div>
		}
		<div className = "preview">
		<Scroll>
		{showTFPrev && showPrev &&
		<ChequePreview dispatch = { dispatch } state = { state } id = { appState.user._id } color = "green" currentUser = {currentUser} currentAccount = {currentAccount} online = { online }/>
		}
		</Scroll>
		</div>
		</React.Fragment>
		}
		</div>
		</div>
		<UserPage page = {!online ? "writeCheck" : "sendCheck"}>
		{state.accounts.length ? (
		<div className="container container--preview"> 
		<div className="card o-hidden border-0 shadow-lg my-5">
		<div className="card-body p-0">

		<div className="row">
		<div className="col-lg-12">
		<div className="p-5">
		<div className="text-center">
		</div>
		<form className="user" onSubmit = { submitDataHandler }>
		<div className="form-group row">
		<div className="col-sm-6 inputContainer">
		<label>Payee <span style={{color:'red'}}>*</span></label>
		{!online && 
			<input onChange = { e => dispatch({type: "payeeChange", value: e.target.value}) } type="text" className="form-control form-control-user"
			value = {state.payee.value}/>
		}
		{online &&
		<select onChange = { onChangePayeeHandler } value = { state.payee.value } className="form-control form-select">
		<option value="-1" payeeId = "0">Pick a payee ...</option>
		{ state.payees.map((payee, idx) => {
			return <option key = { idx } value = { payee.name } payeeId = {payee.id}>{ payee.email }</option>
		})}
		</select>
		}
		<CSSTransition in={state.payee.hasErrors} timeout={0} classNames="dialog" unmountOnExit>
		<div className = "alert small alert--warning">{ state.payee.message}</div>
		</CSSTransition>
		</div>
		<div className="col-sm-6 mb-3 mb-sm-0 inputContainer">
		<label>Amount (Numeric Dollars) <span style={{color:'red'}}>*</span></label>
		<input onChange = { e => dispatch({type: "amountChange", value: e.target.value}) } type="number" step="0.01" className="form-control form-control-user"
		value = {state.amount.value}/>
		<CSSTransition in={state.amount.hasErrors} timeout={0} classNames="dialog" unmountOnExit>
		<div className = "alert small alert--warning">{ state.amount.message}</div>
		</CSSTransition>
		</div>
		</div>

		<div className="form-group row">
		<div className="col-sm-6 mb-3 mb-sm-0 inputContainer">
		<label>Choose an account <span style={{color:'red'}}>*</span></label>
		<select onChange = { e => dispatch({type: "bankAccChange", value: e.target.value}) } value = { state.bankAcc.value } className="form-control form-select">
		<option value="-1">Pick an account ...</option>
		{ state.accounts.map((acc, idx) => {
			return <option key = { idx } value = { acc.id }>{ acc.name }</option>
		})}
		</select>
		<CSSTransition in={state.bankAcc.hasErrors} timeout={0} classNames="dialog" unmountOnExit>
		<div className = "alert small alert--warning">{ state.bankAcc.message}</div>
		</CSSTransition>
		</div>
		
	
		</div>

		<div className="form-group row">
		<div className="col-sm-6 mb-3 mb-sm-0 inputContainer">
		<label>Memo</label>
		<input onChange = { e => dispatch({type: "memoChange", value: e.target.value}) } type="text" className="form-control form-control-user"
		value = {state.memo.value}/>
		</div>
		<div className="col-sm-6 inputContainer">
		<label>Date Format</label>
		<select onChange = { e => dispatch({type: "dateFormatChange", value: e.target.value}) } value = { state.dateFormat.value } className="form-control form-select">
		<option value="1">DDMMYYYY</option>
		<option value="2">MMDDYYYY</option>
		</select>
		<CSSTransition in={state.dateFormat.hasErrors} timeout={0} classNames="dialog" unmountOnExit>
		<div className = "alert small alert--warning">{ state.dateFormat.message}</div>
		</CSSTransition>
		</div>
		</div>

		<div className="form-group row">
		<div className="col-sm-6 inputContainer datePicker dateIndex">
		<label>Deposit Date</label>
		<DatePicker
		selected = { state.depDate }
		onChange = { date => dispatch({type: "depDateChange", value: date}) }
		dropdownMode="select"
		showMonthDropdown
		showYearDropdown
		adjustDateOnChange
		className="form-control form-select myDate"
		/>
		</div>
		</div>











		<div className="form-group row">
		
		<div className="col-sm-6 mb-3 mb-sm-0 inputContainer">
		<div>
		<input onChange = { e => dispatch({type: "nameAppearedImmediately", value: e.target.checked}) } type="checkbox" className="form-checkbox" checked = { state.nameAppeared.value ? true : false}/>
	    <label className="checkbox-label">
	    	<small>Print your name on the check?</small>
	    </label>
	    </div>
		</div>

		<div className="col-sm-6 mb-3 mb-sm-0 inputContainer">
		<div>
	    <input onChange = { e => dispatch({type: "addressAppearedImmediately", value: e.target.checked}) } type="checkbox" className="form-checkbox" checked = { state.addressAppeared.value ? true : false}/>
	    <label className="checkbox-label">
	    	<small>Print your address on the check?</small>
	    </label>
	    </div>
		</div>
		
		</div>

		<div className="form-group row">
		
		<div className="col-sm-6 mb-3 mb-sm-0 inputContainer">
		<div>
	    <input onChange = { e => dispatch({type: "logoAppearedImmediately", value: e.target.checked}) } type="checkbox" className="form-checkbox" checked = { state.logoAppeared.value ? true : false}/>
	    <label className="checkbox-label">
	    	<small>Print your logo on the check?</small>
	    </label>
	    </div>
		</div>

		<div className="col-sm-6 mb-3 mb-sm-0 inputContainer">
		<div>
	    <input onChange = { e => dispatch({type: "phoneAppearedImmediately", value: e.target.checked}) } type="checkbox" className="form-checkbox" checked = { state.phoneAppeared.value ? true : false}/>
	    <label className="checkbox-label">
	    	<small>Print your phone number on the check?</small>
	    </label>
	    </div>
		</div>

		</div>

		<div className="form-group row">
		
		<div className="col-sm-6 mb-3 mb-sm-0 inputContainer">
		<div>
	    <input onChange = { e => dispatch({type: "postalcodeAppearedImmediately", value: e.target.checked}) } type="checkbox" className="form-checkbox" checked = { state.postalcodeAppeared.value ? true : false}/>
	    <label className="checkbox-label">
	    	<small>Print your postal code on the check?</small>
	    </label>
	    </div>
		</div>

		</div>

		<button type = "submit" className="btn btn-primary btn-user btn-block">
		Submit
		</button>
		
		</form>
		</div>
		</div>
		</div>
		</div>
		</div>

		</div>) : (
			<div className="row">
			<div className="col-lg-12">
			<div className="p-5">
				<div class="card shadow mb-4 paragraph">
				<div class="card-header py-3">
					<h6 class="m-0 font-weight-bold text-primary">Dear {appState.user.name}</h6>
				</div>
				<div class="card-body">
					To write/print a check, at least one bank account must be created with your username.
					<div className = "mt-4">
						<a href="#" className="btn btn-primary btn-icon-split">
							<span className="icon text-white-50">
								<i className="fas fa-money-check"></i>
							</span>
							<Link to = "new-account" className="btn btn-primary btn-user">Create an account</Link>
						</a>
					</div>
				</div>
			</div>
			</div>
			</div>
			</div>
		)
		}
		</UserPage>
		</React.Fragment>
		)
}

export default PrintQ