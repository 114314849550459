import React, { Component } from 'react'
import QRCode from 'qrcode.react'

import './Cheque.css'

const ChequePreview = (props) => {

  const addStars = (word) => {
    let value = `**${word}**`
    return value
  }

  const addZeroes = (num) => {
    // Convert input string to a number and store as a variable.
      var value = Number(num);      
    // Split the input string into two arrays containing integers/decimals
      var res = num.split(".");     
    // If there is no decimal point or only one decimal place found.
      if(res.length === 1 || res[1].length < 3) { 
    // Set the number to two decimal places
          value = value.toFixed(2);
      }
  // Return updated or original number.
    return value;
  }
  console.log("Preview Props are: ", props.currentAccount)
  return (
      <div className = "chequeTemp chequeTemp--height">
      {props.currentAccount &&
      <div className = "chequeTemp_chequeNum">
        <div className = "chequeNum">{props.currentAccount.check_num}</div>
      </div>
      }
      
      <div className = "appAd">
      <div>Digitally Verifiable @ crypto.fiyge.com</div>
      <div>crypto.fiyge.com</div>
      <div>Verify the check @ crypto.fiyge.com</div>
      </div>

      <div className = "chequeTemp_user_info" style={{backgroundImage: `url(${props.state.templateUrl})`}}>
        <div className = "chequeTemp_top">
          <div className = "chequeTemp_personInfo">
            {props.state.logoAppeared.value && props.currentAccount && props.currentAccount.logo && props.currentAccount.logo.url && props.currentAccount.logo.url.length ? (<div><img src = { props.currentAccount.logo.url } className = "chequeTemp_personInfo_Logo"/></div>) : ('')}
            <div>
            <div className = "chequeTemp_personInfo_phone">Payer ID: { props.currentUser && props.currentUser._id && props.currentUser._id }</div>
            <div className = "chequeTemp_personInfo_name">{ props.state.nameAppeared.value && props.currentAccount && props.currentAccount.name && props.currentAccount.name.length && props.currentAccount.name }</div>
            <div className = "chequeTemp_personInfo_address">{ props.state.addressAppeared.value && <React.Fragment>{props.currentAccount && props.currentAccount.address && props.currentAccount.address.length && props.currentAccount.address} </React.Fragment> }</div>
            <div className = "chequeTemp_personInfo_phone">{ props.state.addressAppeared.value && <React.Fragment>{props.currentAccount && props.currentAccount.province && props.currentAccount.province.length && props.currentAccount.province} {props.currentAccount && props.currentAccount.city && props.currentAccount.city.length && props.currentAccount.city} </React.Fragment>} { props.currentAccount && props.state.postalcodeAppeared.value && props.currentAccount.postalcode && props.currentAccount.postalcode.length && props.currentAccount.postalcode } { props.currentAccount && props.state.phoneAppeared.value && props.currentAccount.phone && props.currentAccount.phone.length && props.currentAccount.phone }</div>
            </div>
          </div>
          {props.currentUser && props.currentUser._id && props.currentUser._id.length &&
          <div className = "QR_Info">
          <div className = "chequeTemp_id"><QRCode value = { props.currentUser && props.currentUser._id && props.currentUser._id } size = "50"/></div>
          </div>
        }



          <div className = "chequeTemp_topRight">
            <div className = "chequeTemp_Date">
              <span>Date</span>
              <div className = "dateFormat">
                <input readOnly type="text" className = "inputField--line inputField--date" value = {props.state.savedDepDate}/>
                <div className = "dateFormatHolder text-gray-700">
                {props.state.dateFormat.value === '1' && `DD-MM-YYYY`}
                {props.state.dateFormat.value === '2' && `MM-DD-YYYY`}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className = "chequeTemp_Payee">
          <div>Pay to the</div>
          <div className = "chequeTemp_Payee_2ndLine">
            <div>
              Order of 
              <input readOnly value = {addStars(props.state.payee.value)} type="text" className = "inputField--line inputField--line--long" placeholder = "Payee Information"/>
            </div>
            <div className = "chequeTemp_amount">
              $
              <input readOnly value = {addStars(addZeroes(props.state.amount.value))} type="text" className = "inputField--simple" placeholder = "Amount in digits"/> 
            </div>
          </div>
          <div className = "chequeTemp_amount_word">
            <input readOnly value = {addStars(props.state.amountWord)} type="text" className = "inputField--line inputField--line--longest" placeholder = "Amount in words"/>
            Dollars
          </div>
        </div>
        
        <div className = "chequeTemp_bottom">
          <div className = "chequeTemp_bottom_left">
            For 
            <input readOnly type="text" className = "inputField--line inputField--line--for" value = {props.state.memo.value}/>
          </div>
          <div className = "chequeTemp_bottom_right">
            Signature 
            <input type="text" className = "inputField--line inputField--line--for--right" readOnly/>
          </div>
        </div>
        </div>

      </div>
    ) 
}

export default ChequePreview