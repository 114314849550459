import React, { useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { useImmerReducer } from 'use-immer'
import { CSSTransition } from "react-transition-group"
import Axios from 'axios'
import { withRouter } from 'react-router-dom'

import { getCookie } from '../../../helpers/auth'
import DispatchContext from '../../../DispatchContext'

import UserPage from '../../UserPage/UserPage'
import FileUpload from '../../../components/FileUpload/FileUpload'
import Title from '../../../components/Title/Title'

const UpdateInformation = ({ history, match }) => {
  const appDispatch = useContext(DispatchContext)

  const rePhone = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/

  const initialState = {
    name: {
      value: "",
      hasErrors: false,
      message: "",
    },
    province: {
      value: "",
      hasErrors: false,
      message: "",
    },
    city: {
      value: "",
      hasErrors: false,
      message: "",
    },
    address: {
      value: "",
      hasErrors: false,
      message: "",
    },
    postalcode: {
      value: "",
      hasErrors: false,
      message: "",
    },
    phone: {
      value: "",
      hasErrors: false,
      message: "",
    },
    logo: {
      url: "",
      public_id: "",
      hasErrors: false,
      message: "",
    },
    nameAppeared: {
      value: true
    },
    addressAppeared: {
      value: true
    },
    logoAppeared: {
      value: true
    },
    phoneAppeared: {
      value: false
    },
    postalcodeAppeared: {
      value: true
    },
    pageStates: [
      { "name": "Alberta", "abbreviation": "AB" },
      { "name": "British Columbia", "abbreviation": "BC" },
      { "name": "Manitoba", "abbreviation": "MB" },
      { "name": "New Brunswick", "abbreviation": "NB" },
      { "name": "Newfoundland and Labrador", "abbreviation": "NL" },
      { "name": "Northwest Territories", "abbreviation": "NT" },
      { "name": "Nova Scotia", "abbreviation": "NS" },
      { "name": "Nunavut", "abbreviation": "NU" },
      { "name": "Ontario", "abbreviation": "ON" },
      { "name": "Prince Edward Island", "abbreviation": "PE" },
      { "name": "Quebec", "abbreviation": "QC" },
      { "name": "Saskatchewan", "abbreviation": "SK" },
      { "name": "Yukon Territory", "abbreviation": "YT" }
    ],
    submitCount: 0
  }

  const infoReducer = (draft, action) => {
    switch (action.type) {
      // Immediate Updates
      case "nameImmediately":
        draft.name.hasErrors = false
        draft.name.value = action.value
        return

      case "provinceImmediately":
        draft.province.hasErrors = false
        draft.province.value = action.value
        return

      case "cityImmediately":
        draft.city.hasErrors = false
        draft.city.value = action.value
        return

      case "addressImmediately":
        draft.address.hasErrors = false
        draft.address.value = action.value
        return

      case "postalcodeImmediately":
        draft.postalcode.hasErrors = false
        draft.postalcode.value = action.value
        return

      case "logoImmediately":
        draft.logo.hasErrors = false
        draft.logo.url = action.url
        draft.logo.public_id = action.public_id
        return

      case "phoneImmediately":
        draft.phone.hasErrors = false
        draft.phone.value = action.value
        return

      case "nameAppearedImmediately":
        draft.nameAppeared.value = action.value ? 1 : 0
        return

      case "addressAppearedImmediately":
        draft.addressAppeared.value = action.value ? 1 : 0
        return

      case "logoAppearedImmediately":
        draft.logoAppeared.value = action.value ? 1 : 0
        return

      case "phoneAppearedImmediately":
        draft.phoneAppeared.value = action.value ? 1 : 0
        return

      case "postalcodeAppearedImmediately":
        draft.postalcodeAppeared.value = action.value ? 1 : 0
        return

      // After Delay / Validation Checks
      case "nameAfterDelay":
        if (!draft.name.value || !draft.name.value.trim()) {
          draft.name.hasErrors = true
          draft.name.message = "Name is required."
        }
        return

      case "provinceAfterDelay":
        if (!draft.province.value || !draft.province.value.trim() || draft.province.value === "-1") {
          draft.province.hasErrors = true
          draft.province.message = "Province is required."
        }
        return

      case "cityAfterDelay":
        if (!draft.city.value || !draft.city.value.trim()) {
          draft.city.hasErrors = true
          draft.city.message = "City is required."
        }
        return

      case "addressAfterDelay":
        if (!draft.address.value || !draft.address.value.trim()) {
          draft.address.hasErrors = true
          draft.address.message = "Address is required."
        }
        return

      case "postalcodeAfterDelay":
        if (!draft.postalcode.value || !draft.postalcode.value.trim()) {
          draft.postalcode.hasErrors = true
          draft.postalcode.message = "Postal code is required."
        }
        return

      case "phoneAfterDelay":
        if (!draft.phone.value || !draft.phone.value.trim()) {
          draft.phone.hasErrors = true
          draft.phone.message = "Phone number is required."
        } else if (!rePhone.test(draft.phone.value)) {
          draft.phone.hasErrors = true
          draft.phone.message = "Valid phone number format is +x (xxx) xxx-xxxx"
        }
        return

      case "logoAfterDelay":
        if (!draft.logo.url || !draft.logo.url.trim()) {
          draft.logo.hasErrors = true
          draft.logo.message = "Logo is required."
        }
        return

      case "submitForm":
        if (
          !draft.name.hasErrors &&
          !draft.province.hasErrors &&
          !draft.city.hasErrors &&
          !draft.address.hasErrors &&
          !draft.postalcode.hasErrors &&
          !draft.phone.hasErrors &&
          !draft.logo.hasErrors
        ) {
          draft.submitCount++
        }
        return
    }
  }

  const [state, dispatch] = useImmerReducer(infoReducer, initialState)

  useEffect(() => {
    findUser()
  }, [])

  const findUser = async () => {
    const token = getCookie("token")
    try {
      appDispatch({ type: "loadingOn" })
      let response = await Axios.get(`${process.env.REACT_APP_API}/info`, {
        headers: {
          Authorization: token
        }
      })
      appDispatch({ type: "loadingOff" })
      if (response.data) {
        dispatch({ type: "nameImmediately", value: response.data.name })
        dispatch({ type: "provinceImmediately", value: response.data.province })
        dispatch({ type: "cityImmediately", value: response.data.city })
        dispatch({ type: "addressImmediately", value: response.data.address })
        dispatch({ type: "postalcodeImmediately", value: response.data.postalcode })
        dispatch({ type: "phoneImmediately", value: response.data.phone })
        dispatch({ type: "nameAppearedImmediately", value: response.data.name_appeared })
        dispatch({ type: "addressAppearedImmediately", value: response.data.address_appeared })
        dispatch({ type: "logoAppearedImmediately", value: response.data.logo_appeared })
        dispatch({ type: "phoneAppearedImmediately", value: response.data.phone_appeared })
        dispatch({ type: "postalcodeAppearedImmediately", value: response.data.postalcode_appeared })
        dispatch({ type: "logoImmediately", url: response.data.logo.url, public_id: response.data.logo.public_id })
      }
    } catch (err) {
      appDispatch({ type: "loadingOff" })
      console.log(err)
    }
  }

  useEffect(() => {
    if (state.name.value) {
      const delay = setTimeout(() => dispatch({ type: "nameAfterDelay" }), 800)
      return () => clearTimeout(delay)
    }
  }, [state.name.value])

  useEffect(() => {
    if (state.phone.value) {
      const delay = setTimeout(() => dispatch({ type: "phoneAfterDelay" }), 800)
      return () => clearTimeout(delay)
    }
  }, [state.phone.value])

  useEffect(() => {
    if (state.submitCount) {
      infoSubmit()
    }
  }, [state.submitCount])

  const infoSubmit = async () => {
    const token = getCookie("token")
    try {
      appDispatch({ type: "loadingOn" })
      let response = await Axios.post(`${process.env.REACT_APP_API}/info/update`, {
        name: state.name.value,
        province: state.province.value,
        city: state.city.value,
        address: state.address.value,
        postalcode: state.postalcode.value,
        logo: { url: state.logo.url, public_id: state.logo.public_id },
        phone: state.phone.value,
        name_appeared: state.nameAppeared.value,
        address_appeared: state.addressAppeared.value,
        logo_appeared: state.logoAppeared.value,
        phone_appeared: state.phoneAppeared.value,
        postalcode_appeared: state.postalcodeAppeared.value,
      }, {
        headers: {
          Authorization: token
        }
      })
      appDispatch({ type: "loadingOff" })
      if (response.data._id) {
        appDispatch({ type: "flashMessage", value: "Congrats! Your information has been successfully updated." })
        history.push('/info')
      }
    } catch (err) {
      appDispatch({ type: "loadingOff" })
      appDispatch({ type: "errorMessage", value: "Something went wrong; try later!" })
      console.log(err)
    }
  }

  const submitHandler = (e) => {
    e.preventDefault()
    // Immediate validations
    dispatch({ type: "nameImmediately", value: state.name.value })
    dispatch({ type: "provinceImmediately", value: state.province.value })
    dispatch({ type: "cityImmediately", value: state.city.value })
    dispatch({ type: "addressImmediately", value: state.address.value })
    dispatch({ type: "postalcodeImmediately", value: state.postalcode.value })
    dispatch({ type: "phoneImmediately", value: state.phone.value })
    dispatch({ type: "logoImmediately", url: state.logo.url, public_id: state.logo.public_id })
    dispatch({ type: "nameAppearedImmediately", value: state.nameAppeared.value })
    dispatch({ type: "addressAppearedImmediately", value: state.addressAppeared.value })
    dispatch({ type: "logoAppearedImmediately", value: state.logoAppeared.value })
    dispatch({ type: "phoneAppearedImmediately", value: state.phoneAppeared.value })
    dispatch({ type: "postalcodeAppearedImmediately", value: state.postalcodeAppeared.value })

    // After delay validations
    dispatch({ type: "nameAfterDelay" })
    dispatch({ type: "provinceAfterDelay" })
    dispatch({ type: "cityAfterDelay" })
    dispatch({ type: "addressAfterDelay" })
    dispatch({ type: "postalcodeAfterDelay" })
    dispatch({ type: "phoneAfterDelay" })
    dispatch({ type: "logoAfterDelay" })

    dispatch({ type: "submitForm" })
  }

  const logoRemoveHandler = () => {
    dispatch({ type: "logoImmediately", url: "", public_id: "" })
  }

  return (
    <Title title="Update Information">
      <UserPage page="info">
        <div className="container">
          <div className="card o-hidden border-0 shadow-lg my-5">
            <div className="card-body p-0">
              <div className="row">
                <div className="col-lg-12">
                  <div className="p-5">
                    <div className="text-center">
                      <h1 className="h4 text-gray-900 mb-4">Update Information</h1>
                    </div>
                    <form className="user" onSubmit={submitHandler}>
                      <div className="form-group row">
                        <div className="col-sm-6 mb-3 mb-sm-0 inputContainer">
                          <label>Name <span style={{ color: 'red' }}>*</span></label>
                          <input onChange={e => dispatch({ type: "nameImmediately", value: e.target.value })} type="text" className="form-control form-control-user" value={state.name.value} />
                          <CSSTransition in={state.name.hasErrors} timeout={0} classNames="dialog" unmountOnExit>
                            <div className="alert small alert--warning">{state.name.message}</div>
                          </CSSTransition>
                        </div>

                        <div className="col-sm-6 inputContainer">
                          <label>Province <span style={{ color: 'red' }}>*</span></label>
                          <select onChange={e => dispatch({ type: "provinceImmediately", value: e.target.value })} className="form-control form-select" value={state.province.value}>
                            <option value="-1">Please choose a province</option>
                            {state.pageStates.map((prov, idx) => {
                              return <option key={idx} value={prov.abbreviation}>{prov.abbreviation}</option>
                            })}
                          </select>
                          <CSSTransition in={state.province.hasErrors} timeout={0} classNames="dialog" unmountOnExit>
                            <div className="alert small alert--warning">{state.province.message}</div>
                          </CSSTransition>
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="col-sm-6 mb-3 mb-sm-0 inputContainer">
                          <label>City <span style={{ color: 'red' }}>*</span></label>
                          <input onChange={e => dispatch({ type: "cityImmediately", value: e.target.value })} type="text" className="form-control form-control-user" value={state.city.value} />
                          <CSSTransition in={state.city.hasErrors} timeout={0} classNames="dialog" unmountOnExit>
                            <div className="alert small alert--warning">{state.city.message}</div>
                          </CSSTransition>
                        </div>
                        <div className="col-sm-6 inputContainer">
                          <label>Address <span style={{ color: 'red' }}>*</span></label>
                          <input onChange={e => dispatch({ type: "addressImmediately", value: e.target.value })} type="text" className="form-control form-control-user" value={state.address.value} />
                          <CSSTransition in={state.address.hasErrors} timeout={0} classNames="dialog" unmountOnExit>
                            <div className="alert small alert--warning">{state.address.message}</div>
                          </CSSTransition>
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="col-sm-6 mb-3 mb-sm-0 inputContainer">
                          <label>Postal Code <span style={{ color: 'red' }}>*</span></label>
                          <input onChange={e => dispatch({ type: "postalcodeImmediately", value: e.target.value })} type="text" className="form-control form-control-user" value={state.postalcode.value} />
                          <CSSTransition in={state.postalcode.hasErrors} timeout={0} classNames="dialog" unmountOnExit>
                            <div className="alert small alert--warning">{state.postalcode.message}</div>
                          </CSSTransition>
                        </div>

                        <div className="col-sm-6 mb-3 mb-sm-0 inputContainer">
                          <label>Phone Number <span style={{ color: 'red' }}>*</span></label>
                          <input onChange={e => dispatch({ type: "phoneImmediately", value: e.target.value })} type="text" className="form-control form-control-user" value={state.phone.value} />
                          <CSSTransition in={state.phone.hasErrors} timeout={0} classNames="dialog" unmountOnExit>
                            <div className="alert small alert--warning">{state.phone.message}</div>
                          </CSSTransition>
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="col-sm-12 mb-3 mb-sm-0 LogoHandler">
                          <label>Photo (Logo) <span style={{ color: 'red' }}>*</span></label>
                          <FileUpload dispatch={dispatch} txt="Photo" />
                          <CSSTransition in={state.logo.hasErrors} timeout={0} classNames="dialog" unmountOnExit>
                            <div className="alert small alert--warning">{state.logo.message}</div>
                          </CSSTransition>
                          {state.logo.url && state.logo.url.length ? (
                            <div className="LogoMessage">
                              <img width="80" height="80" className="img-profile rounded-circle smallLogo" src={state.logo.url} alt="Logo" />
                              <div onClick={() => dispatch({ type: "logoImmediately", url: "", public_id: "" })} className="deleteIcon"><i className="fas fa-times"></i></div>
                            </div>
                          ) : ("")}
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="col-sm-6 mb-3 mb-sm-0 inputContainer">
                          <div>
                            <input onChange={e => dispatch({ type: "nameAppearedImmediately", value: e.target.checked })} type="checkbox" className="form-checkbox" checked={state.nameAppeared.value ? true : false} />
                            <label className="checkbox-label">
                              <small>Print your name on the check?</small>
                            </label>
                          </div>
                        </div>

                        <div className="col-sm-6 mb-3 mb-sm-0 inputContainer">
                          <div>
                            <input onChange={e => dispatch({ type: "addressAppearedImmediately", value: e.target.checked })} type="checkbox" className="form-checkbox" checked={state.addressAppeared.value ? true : false} />
                            <label className="checkbox-label">
                              <small>Print your address on the check?</small>
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="col-sm-6 mb-3 mb-sm-0 inputContainer">
                          <div>
                            <input onChange={e => dispatch({ type: "logoAppearedImmediately", value: e.target.checked })} type="checkbox" className="form-checkbox" checked={state.logoAppeared.value ? true : false} />
                            <label className="checkbox-label">
                              <small>Print your logo on the check?</small>
                            </label>
                          </div>
                        </div>

                        <div className="col-sm-6 mb-3 mb-sm-0 inputContainer">
                          <div>
                            <input onChange={e => dispatch({ type: "phoneAppearedImmediately", value: e.target.checked })} type="checkbox" className="form-checkbox" checked={state.phoneAppeared.value ? true : false} />
                            <label className="checkbox-label">
                              <small>Print your phone number on the check?</small>
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="col-sm-6 mb-3 mb-sm-0 inputContainer">
                          <div>
                            <input onChange={e => dispatch({ type: "postalcodeAppearedImmediately", value: e.target.checked })} type="checkbox" className="form-checkbox" checked={state.postalcodeAppeared.value ? true : false} />
                            <label className="checkbox-label">
                              <small>Print your postal code on the check?</small>
                            </label>
                          </div>
                        </div>
                      </div>

                      <button type="submit" className="btn btn-primary btn-user btn-block">
                        Submit
                      </button>

                      <Link to="/info" className="btn btn-warning btn-user btn-block">
                        Cancel
                      </Link>
                    </form>


                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </UserPage>
    </Title>
  )
}

export default withRouter(UpdateInformation)
