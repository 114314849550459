import React, { useEffect, useContext, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { useImmerReducer } from "use-immer";
import Axios from "axios";
import { Link } from "react-router-dom";
import { QrScanner } from '@yudiel/react-qr-scanner';

import DispatchContext from "../../DispatchContext";
import Title from "../../components/Title/Title";
import Jimp from "jimp/es";
import jsQR from "jsqr";

const Verify = () => {
  const rdate = /\d\d-\d\d-\d\d\d\d/i;
  const ramount = /\d+\.\d\d/i;
  const appDispatch = useContext(DispatchContext);

  const [isScanning, setIsScanning] = useState(false)
  const initialState = {
    checkNum: {
      value: "",
      hasErrors: "",
      messages: "",
    },
    payerId: {
      value: "",
      hasErrors: "",
      messages: "",
    },
    payee: {
      value: "",
      hasErrors: "",
      messages: "",
    },
    amount: {
      value: "",
      hasErrors: "",
      messages: "",
    },
    date: {
      value: "",
      hasErrors: "",
      messages: "",
    },
    accountNum: {
      value: "",
      hasErrors: "",
      messages: "",
    },
    transitNum: {
      value: "",
      hasErrors: "",
      messages: "",
    },
    insNum: {
      value: "",
      hasErrors: "",
      messages: "",
    },
    digitalSign: {
      value: "",
      hasErrors: "",
      messages: "",
    },
    imgUrl: "",
    submitCount: 0,
  };

  const verifyReducer = (draft, action) => {
    switch (action.type) {
      case "checkNumImmediately":
        draft.checkNum.hasErrors = false;
        draft.checkNum.value = action.value;
        return;

      case "checkNumAfterDelay":
        if (
          draft.checkNum.value.length < 1 ||
          draft.checkNum.value.length > 8
        ) {
          draft.checkNum.hasErrors = true;
          draft.checkNum.message = "Please enter a valid Check Number.";
        }
        return;

      case "payerIdImmediately":
        draft.payerId.hasErrors = false;
        draft.payerId.value = action.value;
        return;

      case "payerIdAfterDelay":
        if (
          draft.payerId.value.length < 10 ||
          draft.payerId.value.length > 40
        ) {
          draft.payerId.hasErrors = true;
          draft.payerId.message = "Please enter a valid user id.";
        }
        return;

      case "payeeImmediately":
        draft.payee.hasErrors = false;
        draft.payee.value = action.value;
        return;

      case "payeeAfterDelay":
        if (draft.payee.value.length < 3) {
          draft.payee.hasErrors = true;
          draft.payee.message = "Please enter a valid payee.";
        }
        return;

      case "amountImmediately":
        draft.amount.hasErrors = false;
        draft.amount.value = action.value;
        return;

      case "amountAfterDelay":
        if (!ramount.test(draft.amount.value)) {
          draft.amount.hasErrors = true;
          draft.amount.message = "You must provide a valid amount, e.g. 28.00";
        }
        return;

      case "dateImmediately":
        draft.date.hasErrors = false;
        draft.date.value = action.value;
        return;

      case "dateAfterDelay":
        if (!rdate.test(draft.date.value)) {
          draft.date.hasErrors = true;
          draft.date.message =
            "You must provide a valid date, e.g. DD-MM-YYYY or MM-DD-YYYY";
        }
        return;

      case "accountNumImmediately":
        draft.accountNum.hasErrors = false;
        draft.accountNum.value = action.value;
        return;

      case "accountNumAfterDelay":
        if (
          draft.accountNum.value.length < 6 ||
          draft.accountNum.value.length > 10
        ) {
          draft.accountNum.hasErrors = true;
          draft.accountNum.message = "Please enter a valid accunt number.";
        }
        return;

      case "transitNumImmediately":
        draft.transitNum.hasErrors = false;
        draft.transitNum.value = action.value;
        return;

      case "transitNumAfterDelay":
        if (
          draft.transitNum.value.length < 3 ||
          draft.transitNum.value.length > 8
        ) {
          draft.transitNum.hasErrors = true;
          draft.transitNum.message = "Please enter a valid transit number.";
        }
        return;

      case "insNumImmediately":
        draft.insNum.hasErrors = false;
        draft.insNum.value = action.value;
        return;

      case "insNumAfterDelay":
        if (draft.insNum.value.length < 2 || draft.insNum.value.length > 5) {
          draft.insNum.hasErrors = true;
          draft.insNum.message = "Please enter a valid institution number.";
        }
        return;

      case "digitalSignImmediately":
        draft.digitalSign.hasErrors = false;
        draft.digitalSign.value = action.value;
        return;

      case "digitalSignAfterDelay":
        if (draft.digitalSign.value.length < 40) {
          draft.digitalSign.hasErrors = true;
          draft.digitalSign.message = "Please enter a valid Hash Signature.";
        }
        return;

      case "imgUrlImmediately":
        draft.imgUrl = action.value;
        return;

      case "submitForm":
        if (
          !draft.checkNum.hasErrors &&
          !draft.payerId.hasErrors &&
          !draft.payee.hasErrors &&
          !draft.amount.hasErrors &&
          !draft.date.hasErrors &&
          !draft.transitNum.hasErrors &&
          !draft.insNum.hasErrors &&
          !draft.digitalSign.hasErrors
        ) {
          draft.submitCount++;
        }
        return;
      default:
        return
    }
  };

  const [state, dispatch] = useImmerReducer(verifyReducer, initialState);

  useEffect(() => {
    if (state.submitCount) {
      verifySubmit();
    }
  }, [state.submitCount]);

  const verifySubmit = async () => {
    let verifiableData = {
      checkNum: state.checkNum.value,
      payerId: state.payerId.value,
      payee: state.payee.value,
      amount: state.amount.value,
      date: state.date.value,
      accountNumber: state.accountNum.value,
      transitNumber: state.transitNum.value,
      institutionNum: state.insNum.value,
    };
    verifiableData = JSON.stringify(verifiableData);
    try {
      appDispatch({ type: "loadingOn" });
      console.log("Verifiable data when verifying is: ", verifiableData);
      console.log(
        "Digital signature when verifying is: ",
        state.digitalSign.value
      );

      let response = await Axios.post(
        `${process.env.REACT_APP_API}/sign-verify`,
        {
          verifiableData: verifiableData,
          hashed_signature: state.digitalSign.value,
        }
      );
      appDispatch({ type: "loadingOff" });
      console.log("Verification response is: ", response.data);
      if (response.data.isVerified) {
        appDispatch({ type: "flashMessage", value: "Check is Verified!" });
      } else {
        appDispatch({ type: "errorMessage", value: "Check is not verified!" });
      }
    } catch (err) {
      appDispatch({ type: "loadingOff" });
      appDispatch({ type: "errorMessage", value: "Check is not verified!" });
      console.log(err);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    
    dispatch({ type: "checkNumImmediately", value: state.checkNum.value });
    dispatch({ type: "checkNumAfterDelay" });

    dispatch({ type: "payerIdImmediately", value: state.payerId.value });
    dispatch({ type: "payerIdAfterDelay" });

    dispatch({ type: "payeeImmediately", value: state.payee.value });
    dispatch({ type: "payeeAfterDelay" });

    dispatch({ type: "amountImmediately", value: state.amount.value });
    dispatch({ type: "amountAfterDelay" });

    dispatch({ type: "dateImmediately", value: state.date.value });
    dispatch({ type: "dateAfterDelay" });

    dispatch({ type: "accountNumImmediately", value: state.accountNum.value });
    dispatch({ type: "accountNumAfterDelay" });

    dispatch({ type: "transitNumImmediately", value: state.transitNum.value });
    dispatch({ type: "transitNumAfterDelay" });

    dispatch({ type: "insNumImmediately", value: state.insNum.value });
    dispatch({ type: "insNumAfterDelay" });

    dispatch({
      type: "digitalSignImmediately",
      value: state.digitalSign.value,
    });
    dispatch({ type: "digitalSignAfterDelay" });
    dispatch({ type: "submitForm" });
  };


  const handleQR = (result) => {
    if (result) {
      console.log("Scanned data is : ", result);
      const splittedRes = result.split(", ");
      if (splittedRes.length === 8) {
        appDispatch({ type: "flashMessage", value: "Check Data Scanned." });
        dispatch({ type: "checkNumImmediately", value: splittedRes[4] });
        dispatch({ type: "payerIdImmediately", value: splittedRes[0] });
        dispatch({ type: "payeeImmediately", value: splittedRes[5] });
        dispatch({ type: "amountImmediately", value: splittedRes[6] });
        dispatch({ type: "dateImmediately", value: splittedRes[7] });
        dispatch({ type: "accountNumImmediately", value: splittedRes[1] });
        dispatch({ type: "transitNumImmediately", value: splittedRes[2] });
        dispatch({ type: "insNumImmediately", value: splittedRes[3] });
      } else {
        appDispatch({
          type: "flashMessage",
          value: "Hashed Signature Scanned.",
        });
        dispatch({ type: "digitalSignImmediately", value: result });
      }
    }
  };






  const handleUploadImage = (event) =>{

    if(!event?.target?.files[0]){
      appDispatch({ type: "errorMessage", value: "Error uploading files"});
      return
    }

    appDispatch({ type: "loadingOn", value: ""});

    const file = event?.target?.files[0]
    const reader = new FileReader();

    reader.onload = function(){
      const fileData = (reader.result)
      Jimp.read(fileData)
      .then((image) => {
        const qr = jsQR(image.bitmap.data, image.bitmap.width, image.bitmap.height);
        if(qr) handleQR(qr.data)
        else {
          appDispatch({ type: "errorMessage", value: "Could not find QR Code. Please upload a clear image"});
        }
        appDispatch({ type: "loadingOff", value: ""});
      })
      .catch((err) => {
        appDispatch({ type: "loadingOff", value: "" });
        appDispatch({ type: "errorMessage", value: err});

      });
    };

    reader.readAsDataURL(file);
  }


  const handleOnScan = (result) =>{
    appDispatch({ type: "loadingOn", value: ""});
    handleQR(result)
    appDispatch({ type: "loadingOff", value: ""});
    setIsScanning(false)

  }

  return (
    <Title title="Verification">
      <div className="verifyContainer">
        <form className="user--verify" onSubmit={submitHandler}>
          <div className="form-group inputContainer">
            {
              !isScanning ? (
                <>
                  <label className="btn btn-primary w-100 my-2" htmlFor="upload-qr">Upload Image of QR Code</label>
                  <input accept="image/png, image/jpeg"  id="upload-qr" type="file" className="d-none" onChange={handleUploadImage}>
                  </input>
                  <button type="button" className="btn btn-primary w-100 my-2" onClick={()=>setIsScanning(true)}>
                    Scan QR Code
                  </button>  
                </> 
              ) :  (
                <>
                  <button type="button" className="btn btn-primary w-100 my-2" onClick={()=>setIsScanning(false)}>
                    Close
                  </button>  
                  <div id="qr-scanner" className="m-auto">
                    <QrScanner
                        onDecode={(result) =>handleOnScan(result)}
                        onError={(error) => console.log(error?.message)}
                    />
                  </div>
                </>
              
              )
            }
          </div>
          <div className="form-group inputContainer">
            <label>Payee</label>
            <input
              onChange={(e) =>
                dispatch({ type: "payeeImmediately", value: e.target.value })
              }
              type="text"
              className="form-control form-control-user"
              value={state.payee.value}
            />
            <CSSTransition
              in={state.payee.hasErrors}
              timeout={0}
              classNames="dialog"
              unmountOnExit
            >
              <div className="alert small alert--warning">
                {state.payee.message}
              </div>
            </CSSTransition>
          </div>
          <div className="form-group inputContainer">
            <label>Check Number</label>
            <input
              onChange={(e) =>
                dispatch({ type: "checkNumImmediately", value: e.target.value })
              }
              type="text"
              className="form-control form-control-user"
              value={state.checkNum.value}
            />
            <CSSTransition
              in={state.checkNum.hasErrors}
              timeout={0}
              classNames="dialog"
              unmountOnExit
            >
              <div className="alert small alert--warning">
                {state.checkNum.message}
              </div>
            </CSSTransition>
          </div>
          <div className="form-group inputContainer">
            <label>Amount</label>
            <input
              onChange={(e) =>
                dispatch({ type: "amountImmediately", value: e.target.value })
              }
              type="text"
              className="form-control form-control-user"
              value={state.amount.value}
            />
            <CSSTransition
              in={state.amount.hasErrors}
              timeout={0}
              classNames="dialog"
              unmountOnExit
            >
              <div className="alert small alert--warning">
                {state.amount.message}
              </div>
            </CSSTransition>
          </div>
          <div className="form-group inputContainer">
            <label>Date</label>
            <input
              onChange={(e) =>
                dispatch({ type: "dateImmediately", value: e.target.value })
              }
              type="text"
              className="form-control form-control-user"
              value={state.date.value}
            />
            <CSSTransition
              in={state.date.hasErrors}
              timeout={0}
              classNames="dialog"
              unmountOnExit
            >
              <div className="alert small alert--warning">
                {state.date.message}
              </div>
            </CSSTransition>
          </div>
          <div className="form-group inputContainer">
            <label>Institution Number</label>
            <input
              onChange={(e) =>
                dispatch({ type: "insNumImmediately", value: e.target.value })
              }
              type="text"
              className="form-control form-control-user"
              value={state.insNum.value}
            />
            <CSSTransition
              in={state.insNum.hasErrors}
              timeout={0}
              classNames="dialog"
              unmountOnExit
            >
              <div className="alert small alert--warning">
                {state.insNum.message}
              </div>
            </CSSTransition>
          </div>
          <div className="form-group inputContainer">
            <label>Transit Number</label>
            <input
              onChange={(e) =>
                dispatch({
                  type: "transitNumImmediately",
                  value: e.target.value,
                })
              }
              type="text"
              className="form-control form-control-user"
              value={state.transitNum.value}
            />
            <CSSTransition
              in={state.transitNum.hasErrors}
              timeout={0}
              classNames="dialog"
              unmountOnExit
            >
              <div className="alert small alert--warning">
                {state.transitNum.message}
              </div>
            </CSSTransition>
          </div>
          <div className="form-group inputContainer">
            <label>Account Number</label>
            <input
              onChange={(e) =>
                dispatch({
                  type: "accountNumImmediately",
                  value: e.target.value,
                })
              }
              type="text"
              className="form-control form-control-user"
              value={state.accountNum.value}
            />
            <CSSTransition
              in={state.accountNum.hasErrors}
              timeout={0}
              classNames="dialog"
              unmountOnExit
            >
              <div className="alert small alert--warning">
                {state.accountNum.message}
              </div>
            </CSSTransition>
          </div>
          <div className="form-group inputContainer">
            <label>Payer ID</label>
            <input
              onChange={(e) =>
                dispatch({ type: "payerIdImmediately", value: e.target.value })
              }
              type="text"
              className="form-control form-control-user"
              value={state.payerId.value}
            />
            <CSSTransition
              in={state.payerId.hasErrors}
              timeout={0}
              classNames="dialog"
              unmountOnExit
            >
              <div className="alert small alert--warning">
                {state.payerId.message}
              </div>
            </CSSTransition>
          </div>
          <div className="form-group inputContainer">
            {/* <div className="btn btn-primary" onClick={onScanFile}>
              Press to Scan Digital Signature QR Code
            </div> */}
            {/* <QrReader
              ref={qrRef}
              delay={300}
              style={{ height: "0" }}
              onError={handleErrorFile}
              onScan={handleScanFile}
              legacyMode
            /> */}
          </div>
          <div className="form-group inputContainer">
            <label>Digital Signature</label>
            <textarea
              onChange={(e) =>
                dispatch({
                  type: "digitalSignImmediately",
                  value: e.target.value,
                })
              }
              className="form-control form-control-user p-1 textArea"
              value={state.digitalSign.value}
            ></textarea>
            <CSSTransition
              in={state.digitalSign.hasErrors}
              timeout={0}
              classNames="dialog"
              unmountOnExit
            >
              <div className="alert small alert--warning">
                {state.digitalSign.message}
              </div>
            </CSSTransition>
          </div>
          <p>
            &#10003; Please verify the details written in fields are correct.
          </p>
          <button type="submit" className="btn btn-primary btn-user btn-block">
            Verify
          </button>
          <Link to="/">
            <button className="btn btn-warning btn-user btn-block verifyBtn">
              Back to Home
            </button>
          </Link>
        </form>
      </div>
    </Title>
  );
};

export default Verify;
