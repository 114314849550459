import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <div className="main-footer">
      <div className="container">
        <div className="row">
          {/* Column1 */}
          <div className="col">
            {/* <h4>CONTACT US</h4>
            <h1 className="list-unstyled">
              <h6>info@cryptochecks.org</h6>
            </h1> */}
          </div>
        </div>
        <hr />
        {/* <div className="row">
          <p className="col-sm">&copy; FIYGE RESEARCH INC.</p>
        </div> */}
      </div>
    </div>
  );
}

export default Footer;
